<template>
  <div>
    <!-- MOBILE -->
    <div v-if="isMobileWidth === 1" class="mb-5 pb-10" style="height:100%">
      <div v-if="reload" class="row pl-3 pr-3 d-flex justify-space-between pt-3">
        <v-card
          width="100%"
          height="270px"
          class="round mb-3"
          elevation="0"
          outlined
          v-for="n in 3"
          :key="n+'A'"
        >
        <v-skeleton-loader
          type="card"
        ></v-skeleton-loader>
        </v-card>
      </div>
      <div v-else>
        <div class="row d-flex justify-space-between pt-3">
            <v-col cols="12" class="text-center pt-0"
            >
                <v-avatar
                color="primary"
                size="110"
                >
                  <v-img :src="dataInflu.photo"></v-img>
                </v-avatar>
            </v-col>
            <v-col cols="12" class="pt-0 text-center"
            >
                <p v-if="isLogin" class="body-1 black--text font-weight-bold mb-1 text-capitalize">{{ dataInflu.name }} <v-icon class="primary--text text-14"> {{ icons.mdiLightningBolt }} </v-icon></p>
                <p v-else class="body-1 black--text font-weight-bold mb-1 text-capitalize">********* <v-icon class="primary--text text-14"> {{ icons.mdiLightningBolt }} </v-icon></p>
                <p class="text-12 mb-1"><i class="fas fa-map-marker-alt mr-2 text-capitalize"></i>{{ dataInflu.city_name }}</p>
                <v-chip
                    class="caption ma-1"
                    color="secondary"
                    v-for="cate in dataInflu.categories" :key="cate.id"
                >{{ cate.name }}
                </v-chip>
            </v-col>
        </div>
        <v-row>
          <v-col  cols="12" class="pt-1 text-center">
            <v-btn v-if="isLogin" @click="book(dataInflu.instagram)" elevation="0" block rounded class="text-capitalize mb-2 mt-2" color="primary"><i class="fas fa-shopping-cart mr-2"></i>Book Now</v-btn>
            <v-btn v-else @click="goToLogin()" elevation="0" block rounded class="text-capitalize mb-2 mt-2" color="primary"><i class="fas fa-eye mr-2"></i>Unblock</v-btn>
          </v-col>
        </v-row>
        <!-- <v-row v-else>
          <v-col cols="6" class="pt-1 text-center">
            <v-btn @click="book(dataInflu.instagram)" large elevation="0" rounded class="text-capitalize mb-2 mt-2" small color="primary"><i class="fas fa-shopping-cart mr-2"></i>Book Now</v-btn>
          </v-col>
          <v-col cols="6" class="pt-1 text-center">
            <v-btn @click="book(dataInflu.instagram)" large elevation="0" rounded class="text-capitalize mb-2 mt-2" small color="primary"><i class="fas fa-shopping-cart mr-2"></i>Book Now</v-btn>
          </v-col>
        </v-row> -->
        <v-row class="mb-2 ml-1 mr-1">
            <v-divider></v-divider>
        </v-row>
        <v-row>
          <v-col cols="12" class="pt-1">
            <v-card class="round third">
              <v-card-text class="text-center black--text">
                <p class="font-weight-bold text-14 black--text mb-2">Summary Instagram</p>
                <v-chip
                    class="caption"
                    color="primary"
                >
                  <p v-if="isLogin" class="mb-0 text-14 white--text"><i class="fab fa-instagram pr-1"></i>{{ dataInflu.instagram ? dataInflu.instagram : 'Tidak tersedia'}}</p>
                  <p v-else class="mb-0 text-14 white--text"><i class="fab fa-instagram pr-1"></i>********</p>
                </v-chip>
                <div class="row mt-3 ml-0 mr-0 d-flex justify-space-between">
                  <v-col cols="6" class="text-center pa-0">
                    <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.follower_ig ? dataInflu.follower_ig : '-' | nFormatter }}</p>
                    <p class="text-12">Followers</p>
                  </v-col>
                  <v-col cols="6" class="text-center pa-0">
                    <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.engagement_rate ? dataInflu.engagement_rate+'%': '-' }}</p>
                    <p class="text-12">Engagement Rate</p>
                  </v-col>
                </div>
                <div class="row mt-3 ml-0 mr-0 d-flex justify-space-between">
                  <v-col cols="6" class="text-center pa-0">
                    <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.avg_like_ig ? dataInflu.avg_like_ig : '-' | nFormatter }}</p>
                    <p class="text-12">Avg. Like</p>
                  </v-col>
                  <v-col cols="6" class="text-center pa-0">
                    <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.avg_comment_ig ? dataInflu.avg_comment_ig : '-' | nFormatter }}</p>
                    <p class="text-12">Avg. Comment</p>
                  </v-col>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="pt-3">
          <v-col cols="12" class="pt-1">
            <v-card class="round fourth">
              <v-card-text class="text-center black--text">
                <p class="font-weight-bold text-14 black--text mb-2">Summary Tiktok</p>
                <v-chip
                    class="caption"
                    color="primary"
                >
                  <p v-if="isLogin" class="mb-0 text-14 white--text"><i class="fab fa-tiktok pr-1"></i>{{ dataInflu.tiktok ? dataInflu.tiktok : ' Tidak tersedia'}}</p>
                  <p v-else class="mb-0 text-14 white--text"><i class="fab fa-tiktok pr-1"></i>{{ dataInflu.tiktok ? '********' : ' Tidak tersedia'}}</p>
                </v-chip>
                <div class="row mt-3 ml-0 mr-0 d-flex justify-space-between">
                  <v-col cols="6" class="text-center pa-0">
                    <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.follower_tiktok ? dataInflu.follower_tiktok : '-' | nFormatter }}</p>
                    <p class="text-12">Followers</p>
                  </v-col>
                  <v-col cols="6" class="text-center pa-0">
                    <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.engagement_rate_tiktok ? dataInflu.engagement_rate_tiktok+'%': '-' }}</p>
                    <p class="text-12">Engagement Rate</p>
                  </v-col>
                </div>
                <div class="row mt-3 ml-0 mr-0 d-flex justify-space-between">
                  <v-col cols="6" class="text-center pa-0">
                    <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.avg_like_tiktok ? dataInflu.avg_like_tiktok : '-' | nFormatter }}</p>
                    <p class="text-12">Avg. Like</p>
                  </v-col>
                  <v-col cols="6" class="text-center pa-0">
                    <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.avg_view_tiktok ? dataInflu.avg_view_tiktok : '-' | nFormatter }}</p>
                    <p class="text-12">Avg. View</p>
                  </v-col>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>

    <!-- DESKTOP -->
    <div v-else class="mb-5 pb-10" style="height:100%">
      <div v-if="reload" class="row d-flex justify-space-between pt-3">
        <v-col cols="4" v-for="n in 3"
            :key="n+'A'">
          <v-card
            width="100%"
            height="270px"
            class="round mb-3"
            elevation="0"
            outlined
          >
          <v-skeleton-loader
            type="card"
          ></v-skeleton-loader>
          </v-card>
        </v-col>
      </div>
      <div v-else>
        <v-card class="round" elevation="0">
          <div class="row pl-3 pr-3">
            <v-col cols="4" class="text-center"
            >
              <v-card class="round">
                <v-img :src="dataInflu.photo"></v-img>
              </v-card>
            </v-col>
            <v-col cols="8" class=""
            >
              <v-row>
                <v-col cols="12" class="pb-0">
                  <p v-if="isLogin" class="body-1 black--text font-weight-bold mb-1 text-capitalize">{{ dataInflu.name }} <v-icon class="primary--text text-14"> {{ icons.mdiLightningBolt }} </v-icon></p>
                  <p v-else class="title black--text font-weight-bold mb-1 text-capitalize">*********  <v-icon class="primary--text text-14"> {{ icons.mdiLightningBolt }} </v-icon></p>
                </v-col>
                <v-col cols="12" class="pb-0 pt-0">
                  <p class="text-12 mb-1"><i class="fas fa-map-marker-alt mr-2 text-capitalize"></i>{{ dataInflu.city_name ? dataInflu.city_name : '-' }}</p>
                </v-col>
                <v-col cols="12" class="pb-0 pt-0">
                  <v-chip
                      class="caption ma-1"
                      color="secondary"
                      v-for="cate in dataInflu.categories" :key="cate.id"
                  >{{ cate.name }}
                  </v-chip>
                </v-col>
                <v-col cols="12" class="pb-0 pt-0">
                  <v-btn v-if="isLogin" @click="book(dataInflu.instagram)" elevation="0" rounded class="text-capitalize mb-2 mt-2" color="primary"><i class="fas fa-shopping-cart mr-2"></i>Book Now</v-btn>
                  <v-btn v-else @click="goToLogin()" elevation="0" rounded class="text-capitalize mb-2 mt-2" color="primary"><i class="fas fa-eye mr-2"></i>Unblock</v-btn>
                </v-col>
              </v-row>
              <v-row class="ml-1 mr-1 pt-2">
                <v-divider></v-divider>
              </v-row>
              <v-row class="pt-2">
                <v-col cols="6" class="pt-1">
                  <v-card class="round third">
                    <v-card-text class="text-center black--text">
                      <p class="font-weight-bold text-14 black--text mb-2">Summary Instagram</p>
                      <v-chip
                          class="caption"
                          color="primary"
                      >
                        <p v-if="isLogin" class="mb-0 text-14 white--text"><i class="fab fa-instagram pr-1"></i>{{ dataInflu.instagram ? dataInflu.instagram : 'Tidak tersedia'}}</p>
                        <p v-else class="mb-0 text-14 white--text"><i class="fab fa-instagram pr-1"></i>********</p>
                      </v-chip>
                      <div class="row mt-3 ml-0 mr-0 d-flex justify-space-between">
                        <v-col cols="6" class="text-center pa-0">
                          <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.follower_ig ? dataInflu.follower_ig : '-' | nFormatter }}</p>
                          <p class="text-12">Followers</p>
                        </v-col>
                        <v-col cols="6" class="text-center pa-0">
                          <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.engagement_rate ? dataInflu.engagement_rate+'%': '-' }}</p>
                          <p class="text-12">Engagement Rate</p>
                        </v-col>
                      </div>
                      <div class="row mt-3 ml-0 mr-0 d-flex justify-space-between">
                        <v-col cols="6" class="text-center pa-0">
                          <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.avg_like_ig ? dataInflu.avg_like_ig : '-' | nFormatter }}</p>
                          <p class="text-12">Avg. Like</p>
                        </v-col>
                        <v-col cols="6" class="text-center pa-0">
                          <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.avg_comment_ig ? dataInflu.avg_comment_ig : '-' | nFormatter }}</p>
                          <p class="text-12">Avg. Comment</p>
                        </v-col>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="6" class="pt-1">
                  <v-card class="round fourth">
                    <v-card-text class="text-center black--text">
                      <p class="font-weight-bold text-14 black--text mb-2">Summary Tiktok</p>
                      <v-chip
                          class="caption"
                          color="primary"
                      >
                        <p v-if="isLogin" class="mb-0 text-14 white--text"><i class="fab fa-tiktok pr-1"></i>{{ dataInflu.tiktok ? dataInflu.tiktok : ' Tidak tersedia'}}</p>
                        <p v-else class="mb-0 text-14 white--text"><i class="fab fa-tiktok pr-1"></i>{{ dataInflu.tiktok ? '********' : ' Tidak tersedia'}}</p>
                      </v-chip>
                      <div class="row mt-3 ml-0 mr-0 d-flex justify-space-between">
                        <v-col cols="6" class="text-center pa-0">
                          <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.follower_tiktok ? dataInflu.follower_tiktok : '-' | nFormatter }}</p>
                          <p class="text-12">Followers</p>
                        </v-col>
                        <v-col cols="6" class="text-center pa-0">
                          <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.engagement_rate_tiktok ? dataInflu.engagement_rate_tiktok+'%': '-' }}</p>
                          <p class="text-12">Engagement Rate</p>
                        </v-col>
                      </div>
                      <div class="row mt-3 ml-0 mr-0 d-flex justify-space-between">
                        <v-col cols="6" class="text-center pa-0">
                          <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.avg_like_tiktok ? dataInflu.avg_like_tiktok : '-' | nFormatter }}</p>
                          <p class="text-12">Avg. Like</p>
                        </v-col>
                        <v-col cols="6" class="text-center pa-0">
                          <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.avg_view_tiktok ? dataInflu.avg_view_tiktok : '-' | nFormatter }}</p>
                          <p class="text-12">Avg. View</p>
                        </v-col>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-card>
        <!-- <v-row class="mb-2 ml-1 mr-1">
            <v-divider></v-divider>
        </v-row> -->
        <!-- <v-row>
          <v-col cols="6" class="pt-1">
            <v-card class="round third">
              <v-card-text class="text-center black--text">
                <p class="font-weight-bold text-14 black--text mb-2">Summary Instagram</p>
                <v-chip
                    class="caption"
                    color="primary"
                >
                  <p v-if="isLogin" class="mb-0 text-14 white--text"><i class="fab fa-instagram pr-1"></i>{{ dataInflu.instagram ? dataInflu.instagram : 'Tidak tersedia'}}</p>
                  <p v-else class="mb-0 text-14 white--text"><i class="fab fa-instagram pr-1"></i>********</p>
                </v-chip>
                <div class="row mt-3 ml-0 mr-0 d-flex justify-space-between">
                  <v-col cols="6" class="text-center pa-0">
                    <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.follower_ig ? dataInflu.follower_ig : '-' | nFormatter }}</p>
                    <p class="text-12">Followers</p>
                  </v-col>
                  <v-col cols="6" class="text-center pa-0">
                    <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.engagement_rate ? dataInflu.engagement_rate+'%': '-' }}</p>
                    <p class="text-12">Engagement Rate</p>
                  </v-col>
                </div>
                <div class="row mt-3 ml-0 mr-0 d-flex justify-space-between">
                  <v-col cols="6" class="text-center pa-0">
                    <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.avg_like_ig ? dataInflu.avg_like_ig : '-' | nFormatter }}</p>
                    <p class="text-12">Avg. Like</p>
                  </v-col>
                  <v-col cols="6" class="text-center pa-0">
                    <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.avg_comment_ig ? dataInflu.avg_comment_ig : '-' | nFormatter }}</p>
                    <p class="text-12">Avg. Comment</p>
                  </v-col>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" class="pt-1">
            <v-card class="round fourth">
              <v-card-text class="text-center black--text">
                <p class="font-weight-bold text-14 black--text mb-2">Summary Tiktok</p>
                <v-chip
                    class="caption"
                    color="primary"
                >
                  <p v-if="isLogin" class="mb-0 text-14 white--text"><i class="fab fa-tiktok pr-1"></i>{{ dataInflu.tiktok ? dataInflu.tiktok : ' Tidak tersedia'}}</p>
                  <p v-else class="mb-0 text-14 white--text"><i class="fab fa-tiktok pr-1"></i>{{ dataInflu.tiktok ? '********' : ' Tidak tersedia'}}</p>
                </v-chip>
                <div class="row mt-3 ml-0 mr-0 d-flex justify-space-between">
                  <v-col cols="6" class="text-center pa-0">
                    <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.follower_tiktok ? dataInflu.follower_tiktok : '-' | nFormatter }}</p>
                    <p class="text-12">Followers</p>
                  </v-col>
                  <v-col cols="6" class="text-center pa-0">
                    <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.engagement_rate_tiktok ? dataInflu.engagement_rate_tiktok+'%': '-' }}</p>
                    <p class="text-12">Engagement Rate</p>
                  </v-col>
                </div>
                <div class="row mt-3 ml-0 mr-0 d-flex justify-space-between">
                  <v-col cols="6" class="text-center pa-0">
                    <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.total_like_tiktok ? dataInflu.total_like_tiktok : '-' | nFormatter }}</p>
                    <p class="text-12">Total Likes</p>
                  </v-col>
                  <v-col cols="6" class="text-center pa-0">
                  </v-col>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mdiLightningBoltOutline, mdiLightningBolt } from '@mdi/js'

export default {
  props:['data'],
  setup() {
    return {
      isLoading:true,
      isLogin:false,
      reload:true,
      page:'',
      idInflu:'',
      dataInflu:'',
      isMobileWidth:0,
      icons:{
        mdiLightningBolt
      }
    }
  },
  mounted(){
    this.page = this.$route.query.item
    let user = this.$route.query.user
    user = this.decryptData(user)
    this.idInflu = user
    if(!localStorage.getItem('token')){
      this.isLogin = false
      this.loadInfluencersNot()
    } else {
      this.isLogin=true
      this.loadInfluencers()
    }

    if(user > 0){
      if(this.isMobileWidth === 0){
        if (localStorage.getItem('reloaded')) {
            localStorage.removeItem('reloaded');
            this.reload = false
        } else {
            localStorage.setItem('reloaded', '1');
            location.reload();
        }
        window.scrollTo(0,0);
      }
    } else {
      this.$router.push({name:'/'});
    }

    this.isMobile()
    window.addEventListener('resize', () => {
      this.isMobile()
    })
  },
  methods:{
    decryptData(val) {
      let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg=';
      let decryptData = this.$CryptoJS.AES.decrypt(val, secretKey).toString(this.CryptoJS.enc.Utf8);
      return decryptData;
    },
    loadInfluencers(){
      this.isLoading = true;
      let token = localStorage.getItem('token')
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'influencers/getOneInfluencer/'+this.idInflu, {
          headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          // console.log(response)
          if(response){
            let data = response.data;
            let num = 1
            for(let a=0; a < data.length; a++){
              data[a].num = num++
              // data[a].follower_ig = this.numberFormat(data[a].follower_ig);
              data[a].city_name === null ? data[a].city_name = data[a].city:''
              axios({
                // url: process.env.VUE_APP_API_ENDPOINT + 'influencers/getImage/'+data[a].photo,
                url: process.env.VUE_APP_API_ENDPOINT + 'influencers/getImage/'+data[a].photo,
                headers: {
                  'Authorization': 'Bearer '+ token
                },
                method: 'GET',
                responseType: 'blob',
              }).then((res) => {
                const blob = new Blob([res.data], { type: "application/JPG" });
                data[a].photo = URL.createObjectURL(blob);
              });
            }
            this.dataInflu = data[0]
            // console.log(data)
            setTimeout(() => {
              this.isLoading = false
              this.loadingTable = false
            }, 500);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    loadInfluencersNot(){
      this.isLoading = true;
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'influencers/getOneInfluencerNot/'+this.idInflu).then(response => {
          // console.log(response)
          if(response){
            let data = response.data;
            let num = 1
            for(let a=0; a < data.length; a++){
              data[a].num = num++
              // data[a].follower_ig = this.numberFormat(data[a].follower_ig);
              data[a].city_name === null ? data[a].city_name = data[a].city:''
              axios({
                // url: process.env.VUE_APP_API_ENDPOINT + 'influencers/getImage/'+data[a].photo,
                url: process.env.VUE_APP_API_ENDPOINT + 'influencers/getImage/'+data[a].photo,
                method: 'GET',
                responseType: 'blob',
              }).then((res) => {
                const blob = new Blob([res.data], { type: "application/JPG" });
                data[a].photo = URL.createObjectURL(blob);
              });
            }
            this.dataInflu = data[0]
            setTimeout(() => {
              this.isLoading = false
              this.loadingTable = false
            }, 500);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    book(talent){
      window.open('https://wa.me/6285691701929?text=Halo Admin, Saya tertarik kepada talent *@'+talent+'* untuk bekerjasama. Mohon diinfokan rate cardnya ya. \n Terima kasih', '_blank');
    },
    goToLogin(){
      this.$router.push({name:'login'});
    },
    isMobile() {
      if(window.innerWidth < 778){
        this.isMobileWidth = 1
      } else if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.isMobileWidth = 1
      }else {
        this.isMobileWidth = 0
      }
    },
  },
  filters:{
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    nFormatter(num) {
      if (num >= 1000000000) {
          return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
      }
      if (num >= 1000000) {
          return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
      }
      if (num >= 1000) {
          return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
      }
      return num;
    }
  }
}
</script>
