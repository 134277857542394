<template>
  <div>
    <div v-if="isLoading">
      <div class="d-flex justify-center">
        <v-skeleton-loader
            class="mx-auto pa-1"
            width="400"
            type="card"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mx-auto pa-1"
            width="400"
            type="card"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mx-auto pa-1"
            width="400"
            type="card"
          ></v-skeleton-loader>
      </div>
    </div>
    <div v-else>
      <!-- MOBILE -->
      <div v-if="isMobileWidth === 1">
        <v-row class="ma-4 mt-0 pt-0">
          <v-col cols="12">
            <detail-influencer></detail-influencer>
          </v-col>
        </v-row>
      </div>

      <!-- DESKTOP -->
      <div v-else>
        <v-row class="pt-0">
          <v-col cols="12">
            <detail-influencer></detail-influencer>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import DetailInfluencer from './components/DetailInfluencer.vue'
import axios from 'axios'

export default {
  name: 'influencer',
  metaInfo: {
    title: 'Influencer Detail'
  },
  components: {
    DetailInfluencer
  },
  setup() {
    return {
      isLoading:false,
      lastCampaign:[],
      topContent:[],
      totalInfluencers:[],
      totalImpressions:[],
      isMobileWidth:0
    }
  },
  mounted(){
    this.isMobile()
    window.addEventListener('resize', () => {
      this.isMobile()
    })
  },
  methods:{
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    dateFormat(value){
      return value.toString().slice(0, 10)
    },
    isMobile() {
      if(window.innerWidth < 778){
        this.isMobileWidth = 1
      } else if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.isMobileWidth = 1
      }else {
        this.isMobileWidth = 0
      }
    },
  }
}
</script>
